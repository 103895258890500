.textLayer::selection,
.textLayer span::selection {
  background: #99d3c5;
}

.pdfViewer {
  .page {
    border-radius: 0.75rem; /* rounded-xl */
    border: 1px solid var(--gray-light); /* border border-gray-light */
    margin-bottom: 3rem !important;

    /* md:mx-0 */
    @media (min-width: 768px) {
      margin-right: 0 !important;
      margin-left: 0 !important;
    }

    /* 2xl:mx-auto */
    @media (min-width: 1536px) {
      margin-right: auto !important;
      margin-left: auto !important;
    }
  }

  canvas {
    border-radius: 0.75rem; /* rounded-xl */
  }

  .pdf-page-number-container {
    margin-top: 1rem;
    margin-bottom: 1rem;
    text-align: center;
  }

  .pdf-page-number {
    color: var(--fore);
    opacity: 0.4;
    font-size: 1rem;
    line-height: 1rem;
  }
}
