// TODO: convert this whole file to scss
.reader ::selection {
  background: #99d3c5;
}

.reader iframe {
  z-index: 199;
  position: relative;
}

.epub-container {
  overflow-anchor: none !important;

  @media (max-width: 768px) {
    width: 92% !important;
  }
}

.epub-view {
  // this overrides the assigned height of the epub-view, which is wrong
  // we want it to take up all the space it needs and not use overflow visible
  // this lets the scrolling container work properly and it's padding-bottom to work
  height: max-content !important;
  overflow: visible !important;

  box-sizing: content-box;
  width: 100% !important;
  padding-bottom: 20px;

  @media (max-width: 768px) {
    width: 92% !important;
  }
}

.epub-view svg {
  mix-blend-mode: multiply;
  overflow: visible;
}

.epubjs-hl,
.epubjs-hl g {
  fill: #37b497;
  fill-opacity: 0.15;
  pointer-events: all;
  cursor: pointer;
}

.epubjs-ul {
  stroke-opacity: 0.8;
  pointer-events: all;
  cursor: pointer;
}

.active-el.epubjs-ul line,
.epubjs-ul:hover line {
  stroke: #37b497;
  stroke-opacity: 1;
}

.epubjs-ul rect {
  stroke-width: 0;
}

.epubjs-ul line {
  stroke: #37b497;
  stroke-width: 2;
  stroke-linecap: butt;
  transform: translateY(1px);
  transition: all 0.2s;
}

.epubjs-hl.active-el,
.epubjs-hl.active-el g {
  fill: #99d3c5;
  fill-opacity: 0.6;
}

.active-el rect,
.epubjs-ul:hover rect {
  fill: #99d3c5;
  fill-opacity: 0.75;
}

.annotation-indicator-and-discussion-container>.active-el {
  opacity: 1;
}

.active-el .annotation-reply-count {
  color: #37b497;
}

.active-el .annotation-icon {
  fill: #37b497;
}
