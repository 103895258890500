@font-face {
  font-display: auto;
  font-family: 'MaisonNeue';
  font-style: normal;
  font-weight: 300;
  src: url('../../../assets/fonts/MaisonNeue-Book.woff2');
}

@font-face {
  font-display: auto;
  font-family: 'MaisonNeue';
  font-style: normal;
  font-weight: 400;
  src: url('../../../assets/fonts/MaisonNeue-Medium.woff2');
}

@font-face {
  font-display: auto;
  font-family: 'MaisonNeue';
  src: url('../../../assets/fonts/MaisonNeue-Bold.woff2');
  font-style: normal;
  font-weight: 500;
}
// this duplicates the previous
@font-face {
  font-display: auto;
  font-family: 'MaisonNeue';
  src: url('../../../assets/fonts/MaisonNeue-Bold.woff2');
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-display: auto;
  font-family: 'MaisonNeue-Mono';
  src: url('../../../assets/fonts/MaisonNeue-Mono.woff2');
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-display: auto;
  font-family: 'NewSpirit';
  src: url('../../../assets/fonts/NewSpirit-Light.woff2');
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-display: auto;
  font-family: 'NewSpirit';
  src: url('../../../assets/fonts/NewSpirit-Medium.woff2');
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-display: auto;
  font-family: 'NewSpirit';
  src: url('../../../assets/fonts/NewSpirit-SemiBold.woff2');
  font-style: normal;
  font-weight: 600;
}
