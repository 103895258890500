// Handy tool for generating color ranges https://hihayk.github.io/scale/#4/6/50/80/-51/67/20/14/1D9A6C/29/154/108/white

@import '../base/_fonts.scss';
@import './epubReader.scss';
@import './pdfReader.scss';

@tailwind base;
@tailwind components;
@tailwind utilities;

@function h2rgb($hex) {
  @return red($hex), green($hex), blue($hex);
}

:root {

  // ---------------------------------------------
  // Brand colors - ONLY USE THESE COLORS 2/10/2025
  // ---------------------------------------------

  --brand-aqua: #{h2rgb(#99D3C5)};
  --brand-turquoise: #{h2rgb(#56BBA3)};
  --brand-teal: #{h2rgb(#00545E)};
  --brand-teal-dark: #{h2rgb(#002C31)};

  --brand-orange-light: #{h2rgb(#FEC27C)};
  --brand-red: #{h2rgb(#F24822)};
  --brand-blue-light: #{h2rgb(#7CC7FE)};

  --brand-white: #{h2rgb(#FFFFFF)};
  --brand-black: #{h2rgb(#000000)};

  --brand-gray: #{h2rgb(#4A4D4C)};
  --brand-gray-dark: #{h2rgb(#2D2F2F)};
  --brand-gray-mid: #{h2rgb(#80857F)};
  --brand-gray-mid-100: #{h2rgb(#999893)};

  --brand-cream: #{h2rgb(#FFFBED)};
  --brand-cream-mid: #{h2rgb(#F5EEDD)};

  --brand-black-mid: #{h2rgb(#1A2425)};
  --brand-black-dark: #{h2rgb(#00171A)};

  // ---------------------------------------------

  --scrollbar-width: 12px;
  --scrollbar-thumb-color: rgba(62, 62, 62, 0.6);
  --scrollbar-thumb-radius: 6px;
  --scrollbar-thumb-hover-color: rgba(62, 62, 62, 0.8);
  --scrollbar-thumb-dark-color: rgba(179, 179, 179, 0.6);
  --scrollbar-thumb-dark-hover-color: rgba(179, 179, 179, 0.8);

  --highlight-bright-20: rgba(86, 187, 163, 0.20);

  --pink: #{h2rgb(#ffc6b2)};

  --teal: #{h2rgb(#00545e)};
  --teal-50: #{h2rgb(#aaccae)};
  --teal-100: #{h2rgb(#88b797)};
  --teal-200: #{h2rgb(#66a185)};
  --teal-300: #{h2rgb(#448b78)};
  --teal-400: #{h2rgb(#22756e)};
  --teal-500: #{h2rgb(#00545e)};
  --teal-600: #{h2rgb(#003954)};
  --teal-650: #{h2rgb(#283b37)};
  --teal-700: #{h2rgb(#002149)};
  --teal-800: #{h2rgb(#000e3e)};
  --teal-900: #{h2rgb(#000032)};

  --teal-dark: #{h2rgb(#002C31)};

  --cream-base: #{h2rgb(#ffffff)};
  --cream-default: #{h2rgb(#fffbed)};
  --cream-bright: #{h2rgb(#ffffff)};
  --cream-300: #{h2rgb(#fffcf1)};
  --cream-400: #{h2rgb(#fffbed)};
  --cream-500: #{h2rgb(#fbf5e6)};
  --cream-600: #{h2rgb(#f5eedd)};
  --cream-700: #{h2rgb(#E1D7C0)};
  --cream-750: #{h2rgb(#E3DAC5)};
  --cream-800: #{h2rgb(#dcd3be)};
  --cream-900: #{h2rgb(#bcac85)};
  --cream-1000: #{h2rgb(#999893)};

  --black-default: #{h2rgb(#000000)};
  --black-mid: #{h2rgb(#1c1c1c)};
  --black-light: #{h2rgb(#2D2F2F)};
}

@layer base {
  * {
    &.dark {
      --base: #{h2rgb(#000000)};
      --scene-default: #{h2rgb(#051011)};
      --scene-light: var(--cream-default);
      --scene-bright: #{h2rgb(#1a2425)};
      --scene-follow: var(--black-mid);
      --scene-follow-light: var(--black-light);
      // because it's a dark theme, we get lighter to add contrast
      --scene-300: #{h2rgb(#161f20)};
      --scene-400: #{h2rgb(#051011)}; // = default
      --scene-500: #{h2rgb(#182525)};
      --scene-600: #{h2rgb(#1e2b2b)}; // = shade
      --scene-700: #{h2rgb(#2b3c3e)};
      --scene-750: var(--black-light);
      --scene-800: #{h2rgb(#384d4f)};
      --scene-900: #{h2rgb(#8cacae)};
      --fore: #{h2rgb(#fffbed)};
      --highlight: #{h2rgb(#99d3c5)};
      --highlight-mid: #{h2rgb(#99d3c5)};
      --highlight-light: var(--teal-650);
      --highlight-bright: #{h2rgb(#56BBA3)};
      --accent: var(--highlight);
      --gray: #{h2rgb(#b3b3b3)};
      --gray-light: #{h2rgb(#232929)};
      --gray-mid: #{h2rgb(#909090)};
      --gray-dark: #{h2rgb(#696e69)};
      --gray-100: #{h2rgb(#242424)};
      --gray-200: #{h2rgb(#3e3e3e)};
      --gray-300: #{h2rgb(#5d5c5c)};
      --gray-400: #{h2rgb(#787777)};
      --gray-450: #{h2rgb(#80857F)};
      --gray-500: #{h2rgb(#9a9797)};
      --gray-550: var(--cream-1000);
      --gray-600: #{h2rgb(#c4c1c1)};
      --gray-700: #{h2rgb(#d9d7d7)};
      --gray-750: #{h2rgb(#b5b6b6)};
    }

    &.light {
      --cream-base: var(--cream);
      --scene-default: var(--cream-default);
      --scene-bright: var(--cream-bright);
      --scene-follow: var(--cream-600);
      --scene-follow-light: var(--cream-600);
      --scene-300: var(--cream-300);
      --scene-400: var(--cream-400); // = default
      --scene-500: var(--cream-500);
      --scene-600: var(--cream-600); // = shade
      --scene-700: var(--cream-700);
      --scene-750: var(--cream-750);
      --scene-900: var(--cream-900);
      --fore: #{h2rgb(#002c31)};
      --highlight: #{h2rgb(#37b497)};
      --highlight-mid: #{h2rgb(#56BBA3)};
      --highlight-light: #{h2rgb(#d5e4d1)};
      --highlight-bright: #{h2rgb(#56BBA3)};
      --accent: var(--teal);
      --gray: #{h2rgb(#3e3e3e)};
      --gray-mid: #{h2rgb(#909090)};
      --gray-dark: #{h2rgb(#222222)};
      --gray-light: #{h2rgb(#e5e1d8)};
      --gray-100: #{h2rgb(#d9d7d7)};
      --gray-200: #{h2rgb(#c4c1c1)};
      --gray-300: #{h2rgb(#9a9797)};
      --gray-400: #{h2rgb(#787777)};
      --gray-450: #{h2rgb(#80857F)};
      --gray-500: #{h2rgb(#5d5c5c)};
      --gray-550: var(--black-light);
      --gray-600: #{h2rgb(#3e3e3e)};
      --gray-700: #{h2rgb(#242424)};
      --gray-750: #{h2rgb(#4A4D4C)};
    }
  }

  body {

    /* Styling for Windows scrollbar */
    &.is-windows {

      &::-webkit-scrollbar,
      ::-webkit-scrollbar {
        width: var(--scrollbar-width);
      }

      &::-webkit-scrollbar-thumb,
      ::-webkit-scrollbar-thumb {
        background-color: var(--scrollbar-thumb-color);
        border-radius: var(--scrollbar-thumb-radius);
      }

      &::-webkit-scrollbar-thumb:hover,
      ::-webkit-scrollbar-thumb:hover {
        background-color: var(--scrollbar-thumb-hover-color);
      }
    }

    &.is-windows.dark {

      &::-webkit-scrollbar-thumb,
      ::-webkit-scrollbar-thumb {
        background-color: var(--scrollbar-thumb-dark-color);
      }

      &::-webkit-scrollbar-thumb:hover,
      ::-webkit-scrollbar-thumb:hover {
        background-color: var(--scrollbar-thumb-dark-hover-color);
      }
    }

    @apply text-fore;
    @apply bg-scene;

    h1 {
      @apply font-display;
      @apply font-light;
      @apply text-4xl;
      @apply md:text-5xl;
      @apply 1.5xl:text-6xl;
      @apply 1.5xl:leading-extra-tight;
      @apply pb-6;
      @apply text-fore;
    }

    h2 {
      @apply font-display;
      @apply font-light;
      @apply text-3xl;
      @apply md:text-4xl;
      @apply leading-tight;
      @apply text-fore;
    }

    h3 {
      @apply font-display;
      @apply font-light;
      @apply text-3xl;
      @apply md:text-4xl;
      @apply leading-tight;
    }

    h5 {
      @apply font-sans;
      @apply font-bold;
      @apply text-xs;
    }

    p {
      @apply text-fore;
    }

    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type='number'] {
      -moz-appearance: textfield;
    }
  }

  .hide-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .hide-scrollbar {
    scrollbar-width: none;
    -ms-overflow-style: none;
  }

  .animated-underline {
    background:
      linear-gradient(to right, rgba(153, 211, 197, 0), rgba(153, 211, 197, 0)),
      linear-gradient(to right, rgba(153, 211, 197, 1), rgba(153, 211, 197, 1));
    background-size: 100% 0.1em, 0 0.1em;
    background-position: 100% 100%, 0 100%;
    background-repeat: no-repeat;
    transition: background-size 400ms;
  }

  .animated-underline:hover,
  .animated-underline:focus {
    background-size: 0 0.1em, 100% 0.1em;
  }

  .marketing-underline {
    position: relative;
    z-index: 10;

    &::after {
      content: '';
      position: absolute;
      bottom: 0.25rem;
      left: -0.5rem;
      right: -0.5rem;
      height: 0.75rem;

      // Position the line behind the text so that
      // it is still easily readable
      z-index: -1;

      // The SVG is added as an SVG background image
      background-image: url('https://ik.imagekit.io/threadable/marketing-site/social-underline_HEWg2h-xr-.svg');
      background-repeat: no-repeat;

      // This allows the SVG to flex in size to fit
      // any length of word. If the word is short,
      // the SVG will be stretched vertically, if it
      // is long, the SVG will be stretched horizontally.
      // The jagged nature of this particular SVG works
      // with this transforming.
      background-size: cover;

      @media (min-width: 1024px) {
        bottom: 0.55rem;
      }
    }
  }
}
